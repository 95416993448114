<template>
	<!-- container -->
	<div id="container">
		<h1>Home</h1>
	</div>
	<!--// container -->
</template>
<script>
export default {
	created() {
		this.$router.replace({ name: 'buildingList' });
	},
};
</script>

<style scoped></style>
